<script setup lang="ts">
import { Dayjs } from "dayjs";

import type { Promotion } from "@/types";

const { t } = useT();

const props = defineProps<{
	showAvailable?: boolean;
	enabled?: boolean;
	image: string;
	subTitle: string;
	title: string;
	coins: number;
	entries: number;
	piggyCoins?: number;
	date: string | number | Dayjs;
	type?: Promotion["type"];
	bestDeal: boolean;
	mostPopular: boolean;
	badge?: Record<string | number, string | number | boolean | null | undefined>;
	currencySymbol?: string;
	money?: string;
	textTooltip?: string;
	magicBoxDate?: string;
	magicBoxAvailable?: boolean;
	questSubscribed?: boolean;
	promotionInfo?: boolean;
	usualPrice?: string;
	applePayEnabled?: boolean;
	lastPayProvider?: object | null;
	subType?: string | null;
	showDeposit?: boolean;
	questTitle?: string;
	optionalAction?: boolean;
	hideBtnLabel?: boolean;
}>();

const emit = defineEmits<{ (event: "click"): void; (event: "onInfo"): void; (event: "onCountDownStop"): void }>();
const { ready: readyAnimation, start: handleStartAnimation } = useTimeout(2000, { controls: true, immediate: false });
const { ready: readyPopper, start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });
const { hasDeposits, isVerificationCompleted } = useProfileData();
const isAnimated = ref(false);
const stopAnimationState = () => {
	isAnimated.value = false;
};

const handleClickWithDelay = () => {
	emit("click");
};

const handleClick = () => {
	handleStartAnimation();
	handleStartPopper();

	if ((props.applePayEnabled || props.lastPayProvider) && hasDeposits.value && isVerificationCompleted.value) {
		isAnimated.value = true;

		return;
	}

	emit("click");
};

useEvent(["LEAVE_CASH"], () => {
	stopAnimationState();
});

const onCountDownStop = () => {
	if (props.enabled && props.showAvailable) {
		emit("onCountDownStop");
	}
};

const {
	durationLeft: promotionTime,
	duration,
	reset
} = useCountdown({
	timestamp: props.date,
	formatToken: "H [h] m [m] s [s]",
	onCountdownStop: onCountDownStop
});
const { durationLeft: magicBoxTime } = useCountdown({ timestamp: props.magicBoxDate || "" });

const isTypeOffer = computed(() =>
	["offerDeposit", "offerPeriodic", "offerDate", "offerPersonal", "offerBirthday"].includes(props.type || "")
);

watch(
	() => props.date,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<AAnimationTranslateFlip :animationState="isAnimated" @handle-click-with-delay="handleClickWithDelay">
		<ACard
			:imgAttrs="{
				width: 'auto',
				height: 80,
				src: image,
				format: 'avif',
				loading: 'lazy'
			}"
			width="330px"
			height="310px"
			:class="[
				`card-${type}`,
				{ 'card-disabled': (!enabled && type !== 'quest') || (!magicBoxAvailable && type === 'magicBox') },
				{ 'card-popular': mostPopular },
				{ 'card-best': bestDeal },
				{ 'card-sub-type': subType },
				{ 'card-offer-type': isTypeOffer }
			]"
			@click="handleClick"
		>
			<template #top>
				<ABadge
					v-if="showAvailable && enabled && duration > 0"
					:background="'var(--a-overlay-100)'"
					:class="{ 'offer-timer': mostPopular || bestDeal }"
					variant="info"
					autosize
				>
					<AText variant="topeka" as="div">
						{{ t("Time left") }} <b>{{ promotionTime }}</b>
					</AText>
				</ABadge>
				<ABadge
					v-if="type === 'magicBox' && !magicBoxAvailable"
					:background="'var(--a-overlay-100)'"
					variant="info"
					autosize
				>
					<AText type="x-small" as="div">
						{{ t("Next in") }} <b>{{ magicBoxTime }}</b>
					</AText>
				</ABadge>
				<AText v-if="!isTypeOffer && title" class="title" type="base" :modifiers="['semibold', 'uppercase']">
					{{ type === "quest" ? `${questTitle} ${t("Quest")}` : title }}
				</AText>
				<div v-else-if="bestDeal || mostPopular" class="d-flex justify-content-center align-items-center gap-1">
					<ABadge
						v-if="bestDeal || mostPopular"
						:background="bestDeal ? 'var(--secondary-500)' : 'var(--danger-300)'"
						variant="info"
						autosize
					>
						<AText type="badge" as="div">
							{{ bestDeal ? t("Best deal") : t("Most popular") }}
						</AText>
					</ABadge>
					<ABadge v-if="badge?.percent && badge?.text" variant="info" :modifiers="['outlined']" autosize>
						<AText type="badge" as="div"> {{ badge.percent }} {{ badge.text }} </AText>
					</ABadge>
				</div>
				<div v-if="!bestDeal && !mostPopular && subType" class="d-flex justify-content-center align-items-center gap-1">
					<ABadge
						v-if="!bestDeal && !mostPopular && subType"
						:background="'var(--success-600)'"
						variant="info"
						autosize
					>
						<AText type="badge">
							{{ subType }}
						</AText>
					</ABadge>
					<ABadge v-if="badge?.percent && badge?.text" variant="info" :modifiers="['outlined']" autosize>
						<AText type="badge" as="div"> {{ badge.percent }} {{ badge.text }} </AText>
					</ABadge>
				</div>

				<AText v-if="isTypeOffer && title" class="title" type="base" :modifiers="['bold', 'uppercase']">
					{{ title }}</AText
				>

				<AText v-if="subTitle" class="sub-title" type="small" :modifiers="['bold', 'uppercase']">
					{{ subTitle }}
				</AText>
			</template>
			<template #bottom>
				<div class="box">
					<AText v-if="coins" type="h4" :class="['count coins', { single: !entries }]">
						<ASvg :name="type === 'verification' ? '12/secret-coins' : '12/coins'" class="icon" />
						{{ numberFormat(coins) }}
					</AText>

					<AText v-if="entries" type="h4" class="count entries">
						<ASvg name="12/secret-coins" class="icon" />
						<AText type="h8" class="count coins">
							{{ numberFormat(entries) }}
						</AText>
						<AText v-if="type !== 'quest'" type="xx-small" class="count-after" :modifiers="['normal']">
							{{ t("Free Secret Coins") }}
						</AText>
					</AText>

					<AText v-if="piggyCoins" :size="10" :modifiers="['uppercase']">
						<AText :size="10" :modifiers="['bold']">+ {{ piggyCoins }}</AText>
						{{ t("for Piggy bank") }}
					</AText>
				</div>

				<AButton v-if="type === 'emailLottery'" variant="primary" size="s" class="promo" data-tid="promo-get-email">
					{{ t("Get email") }}
				</AButton>

				<template v-if="type === 'verification'">
					<div class="foot">
						<AText
							class="info-txt"
							type="xx-small"
							:modifiers="['center', 'uppercase']"
							as="div"
							data-tid="promo-verification"
						>
							<i18n-t keypath="Upgrade your account and get {key} for free">
								<template #key>
									<b>{{ t("1000 Secret Coins") }}</b>
								</template>
							</i18n-t>
						</AText>
					</div>
					<AButton variant="primary" size="s" class="promo" data-tid="promo-verification">
						{{ t("Secure now") }}
					</AButton>
				</template>

				<template v-if="type !== 'verification'">
					<AButton
						v-if="type === 'magicBox'"
						:variant="'primary'"
						:modifiers="[!magicBoxAvailable ? 'disabled' : '']"
						size="s"
						class="promo"
						data-tid="promo-magic-box"
					>
						{{ magicBoxAvailable ? t("Try now for free") : t("Coming soon") }}
					</AButton>
					<AButton v-else-if="type === 'quest'" variant="primary" size="s" class="promo" data-tid="promo-quest">
						{{ questSubscribed ? t("Continue") : t("Join now") }}
					</AButton>
					<AButton v-else-if="enabled" variant="primary" size="s" class="promo" data-tid="promo-buy-now">
						{{ !hideBtnLabel ? t("buy now") : "" }} {{ currencySymbol }}{{ money }}
						<NuxtImg
							v-if="showDeposit"
							src="/nuxt-img/deposit-streak/pumpkin-icon.webp"
							width="19"
							height="19"
							alt="deposit-streak-prize"
							loading="lazy"
							format="avif"
							class="btn-icon"
						/>
					</AButton>
					<APopper v-else class="btn-not-allowed" :content="textTooltip" placement="top" :show="!readyPopper">
						<AButton variant="primary" :modifiers="['disabled']" size="s" class="promo" data-tid="promo-not-allowed">
							<AAnimationTada :active="!readyAnimation">
								<NuxtIcon name="20/lock" class="btn-icon" :filled="false" />
							</AAnimationTada>
							{{ currencySymbol }}{{ money }}
						</AButton>
					</APopper>
				</template>

				<div v-if="usualPrice" class="foot">
					<AText type="x-small" :modifiers="['bold', 'uppercase', 'lineThrough']">
						<i18n-t keypath="was {key}">
							<template #key>
								{{ currencySymbol }}<s>{{ usualPrice }}</s>
							</template>
						</i18n-t>
					</AText>
				</div>

				<div v-if="type === 'quest'" class="foot" @click.stop="emit('onInfo')">
					<AText type="x-small" class="color-gray-50" :modifiers="['uppercase', 'bold', 'link', 'underline']">{{
						t("Learn more")
					}}</AText>
				</div>
			</template>
		</ACard>
	</AAnimationTranslateFlip>
</template>

<style scoped lang="scss">
.card {
	color: var(--neutral);
	background: var(--graz);

	&.card-offerDeposit,
	&.card-offerDate {
		background: var(--ghent);
	}
	&.card-verification {
		background: var(--genoa);
	}
	&.card-popular {
		background: var(--gradient-4);
	}
	&.card-quest {
		background: var(--gurgaon);

		.icon:deep(svg) {
			font-size: 28px;
		}

		.count {
			font-size: 28px;
		}
	}
	&.card-magicBox {
		--a-card-img-top: 80px;
		background: var(--gradient-8);
	}
	&.card-sub-type {
		background: var(--gradient-3);
	}
	&.card-offerBirthday {
		background: var(--gonko);
	}
	&.card-best {
		background: var(--gradient-2);
	}

	&.card-popular,
	&.card-best {
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: var(--a-card-default-radius);
			content: "";
			background: url(/nuxt-img/promotions/best-deal-bg.png) no-repeat 50% 50%;
			background-size: cover;
		}
	}

	&.card-popular {
		&:before {
			background: url(/nuxt-img/promotions/most-bg.png) no-repeat 50% 50%;
		}
	}

	.disabled {
		gap: gutter(1);
		box-shadow: 0 4px 10px 0 var(--chile);
		opacity: 1 !important;

		.icon {
			font-size: 20px;
		}
	}
}

.offer-timer {
	position: absolute;
	top: 80px;
}

.info-txt {
	max-width: 180px;
	margin: auto;
}

.btn-not-allowed {
	width: 100%;
}
</style>
